[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  --tootip-bg: #434343;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --text-color-fourth: #adaeb4;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #ff0100;
  --red-text-color1: #e0432b;
  --green-text-color: #09d687;
  --green-text-color1: #2daa40;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue1: #225085;
  --blue2: #507dc9;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  /*---------- app asset colors ----------*/
  --app-asset-bg: #1b1b1b;
  --app-legend-bg: #8c8c8c;
  --app-text-color: #8f8f8f;
  --app-timepicker-bg: #454545;
  /*---------- chart colors ----------*/
  --tooltip-bg: rgba(47, 51, 66, 0.91);
  --statistic-color: #bcbcbc;
  --depth-color: #61688a;
  --depth-tip-color: #cad7e0;
  --depth-bg-color: rgba(0, 0, 0, 0.8);
  --depth-bidsLineColor: #41b37d;
  --depth-asksLineColor: #d74e5a;
  --depth-axisColor: rgba(97, 104, 138, 0.3);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="mode-light"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  --tootip-bg: #434343;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --text-color-fourth: #adaeb4;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #ff0100;
  --red-text-color1: #e0432b;
  --green-text-color: #09d687;
  --green-text-color1: #2daa40;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue1: #225085;
  --blue2: #507dc9;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  /*---------- app asset colors ----------*/
  --app-asset-bg: #1b1b1b;
  --app-legend-bg: #8c8c8c;
  --app-text-color: #8f8f8f;
  --app-timepicker-bg: #454545;
  /*---------- chart colors ----------*/
  --tooltip-bg: rgba(47, 51, 66, 0.91);
  --statistic-color: #bcbcbc;
  --depth-color: #61688a;
  --depth-tip-color: #cad7e0;
  --depth-bg-color: rgba(0, 0, 0, 0.8);
  --depth-bidsLineColor: #41b37d;
  --depth-asksLineColor: #d74e5a;
  --depth-axisColor: rgba(97, 104, 138, 0.3);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7a52f4;
  --saas-main-color: #4e2c5b;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #f8fafc;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030e;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4a4a4a;
  --saas-table-btn-border1: #bc20fe;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ed3ee;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4bbedc;
  --saas-download-color: #a833cc;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31b6d1;
  --saas-investor-not-read-color: #cfbfff;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #aaa8aa;
  --saas-table-th-bg: #262628;
  --saas-contact-textarea-border-color: #4e4e4e;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --login-bg1: #4a4c51;
  --login-bg2: #2e1b3f;
  --market-thead-bg: #262628;
  --editor-menu-border-color: #e5e7eb;
  --editor-a-color: #1677ff;
  --editor-a-hover-color: #69b1ff;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
[data-theme="summer-capital-mode"] {
  --main-bg-color: #000;
  --main-color: #d9a527;
  --saas-main-color: #693a0f;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #f8fafc;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030e;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4a4a4a;
  --saas-table-btn-border1: #d9a527;
  --saas-table-btn-bg1: rgba(217, 165, 39, 0.2);
  --saas-table-btn-border2: #eb892e;
  --saas-table-btn-bg2: rgba(235, 137, 46, 0.2);
  --saas-product-label-bg1: #daa729;
  --saas-product-label-bg2: #693a10;
  --saas-investor-label-bg: rgba(217, 165, 39, 0.2);
  --saas-favorite-bg2: #4bbedc;
  --saas-download-color: #ffdd8c;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31b6d1;
  --saas-investor-not-read-color: #ffe7ac;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #aaa8aa;
  --saas-table-th-bg: #262628;
  --saas-contact-textarea-border-color: #4e4e4e;
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --login-bg1: #6c3500;
  --login-bg2: #201102;
  --market-thead-bg: #262628;
  --editor-menu-border-color: #e5e7eb;
  --editor-a-color: #1677ff;
  --editor-a-hover-color: #69b1ff;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.jDhYso09 {
  word-wrap: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.wL1ZLoP1 {
  background: var(--module-bg);
}
.wL1ZLoP1 .I5gUbpxE {
  display: flex;
  justify-content: space-between;
  height: 56px;
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
}
.wL1ZLoP1 .I5gUbpxE .VSWs_IOz {
  font-size: 22px;
  font-weight: 700;
}
.wL1ZLoP1 .xTUDjPxT {
  padding: 15px;
}
.wL1ZLoP1 .xTUDjPxT .RIRsN8nI:last-of-type .__4M91D0Ph,
.wL1ZLoP1 .xTUDjPxT ._gVlx_HK:last-of-type .__4M91D0Ph {
  display: none;
}
.wL1ZLoP1 .xTUDjPxT .pweRQnUS:last-of-type .C7mLmc4o {
  margin: 0;
}
.U8fb8jza,
.MTL8_yJw {
  display: flex;
}
.U8fb8jza .__3wR0j2fU,
.MTL8_yJw .__3wR0j2fU {
  max-width: 110px;
  height: 18px;
  line-height: 15px;
  background: var(--btn-type-bg);
  color: var(--main-color);
  border-radius: 2px;
  padding: 2px 8px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.MTL8_yJw .__3wR0j2fU {
  padding: 2px 5px;
  background: var(--btn-grey-bg) !important;
  color: var(--white);
}
.MTL8_yJw .__3wR0j2fU:last-child {
  margin-right: 0;
}
.__4M91D0Ph {
  border-bottom: 1px dashed var(--border-color);
  margin: 24px 0;
}
.__2B9Hs4eE {
  min-height: 760px;
}
.__2B9Hs4eE ._FtTUDm6 {
  flex-flow: row nowrap;
}
.__2B9Hs4eE .LiUMsdBz {
  width: 200px;
  height: 100%;
  background: var(--module-bg);
  margin-right: 16px;
}
.__2B9Hs4eE .LiUMsdBz .ant-menu {
  background: transparent;
}
.__2B9Hs4eE .LiUMsdBz .ant-menu .ant-menu-item {
  padding-left: 32px;
  height: 90px;
  line-height: 90px;
  margin: 0;
  border-bottom: 1px solid var(--border-color);
  border-left: 2px solid transparent;
  color: var(--remark-text-color);
  font-size: 18px;
  font-weight: 500;
  font-family: "iconfont" !important;
}
.__2B9Hs4eE .LiUMsdBz .ant-menu .ant-menu-item:active,
.__2B9Hs4eE .LiUMsdBz .ant-menu .ant-menu-item:hover {
  color: var(--main-color);
  background-color: transparent !important;
}
.__2B9Hs4eE .LiUMsdBz .ant-menu .ant-menu-item.ant-menu-item-selected {
  background-color: transparent !important;
  border-left: 2px solid var(--main-color) !important;
  color: var(--main-color);
}
.__2B9Hs4eE .LiUMsdBz .EFOs3xRP {
  display: flex;
  align-items: center;
}
.__2B9Hs4eE.g062QM4f {
  overflow: hidden;
}
.__2B9Hs4eE.g062QM4f .cWw67_in {
  max-height: 940px;
  height: -moz-fit-content;
  height: fit-content;
  overflow-y: scroll;
  margin-bottom: -230px;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .xTUDjPxT {
  height: 142px;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .xTUDjPxT .__1bHu_Eka {
  margin-top: 20px;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 {
  min-height: 100px;
  margin-bottom: 24px;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .wL1ZLoP1.sWkAXyRW .xTUDjPxT {
  padding: 0;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .tWN369Ya {
  padding: 24px;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .tWN369Ya .c16xv7IQ {
  display: flex;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .tWN369Ya .c16xv7IQ .ToveD5id {
  width: 153px;
  height: 96px;
  position: relative;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .tWN369Ya .c16xv7IQ .ToveD5id .BNtUScQ1 {
  width: 71%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
  font-family: var(--CommonBold);
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .tWN369Ya .c16xv7IQ .WBNZW7IH {
  border-radius: 2px;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .tWN369Ya .c16xv7IQ .pf8_Deh9 {
  margin-left: 15px;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .tWN369Ya .c16xv7IQ .__4M91D0Ph {
  border-left: 1px solid var(--border-color2);
}
.__2B9Hs4eE.g062QM4f .cWw67_in .ZSKO2bSj {
  min-height: 826px;
  background: var(--module-bg);
}
.__2B9Hs4eE.g062QM4f .cWw67_in .ZSKO2bSj .__0JyePpSh {
  height: 56px;
  padding: 16px 32px;
  border-bottom: 1px solid var(--border-color);
  font-size: 24px;
  font-weight: 700;
  background: var(--module-bg);
  position: sticky;
  top: 0;
  z-index: 99;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .ZSKO2bSj .pf8_Deh9 {
  min-height: 150px;
}
.__2B9Hs4eE .GNFvTvrz {
  border-bottom: 1px solid var(--border-color);
}
.__2B9Hs4eE .GNFvTvrz .ant-tabs-nav {
  height: 58px;
  margin: 0;
  padding: 0 32px;
  font-weight: 700;
}
.__2B9Hs4eE .GNFvTvrz .ant-tabs-nav .ant-tabs-tab {
  font-size: 22px;
  margin-right: 30px;
  border-bottom: 3px solid transparent;
}
.__2B9Hs4eE .GNFvTvrz .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 3px solid var(--main-color);
}
.__2B9Hs4eE.__1tdHNHfa .VEMcP48L {
  overflow: hidden;
  min-height: 826px;
  background: var(--module-bg);
}
.__2B9Hs4eE.__1tdHNHfa .VEMcP48L .__6TaoxYWZ {
  min-height: 826px;
  max-height: 1000px;
  background: var(--module-bg);
  overflow-y: scroll;
  margin-bottom: -230px;
}
.__2B9Hs4eE.DMPU_uJo .wNK2gADi {
  overflow: hidden;
  min-height: 826px;
  background: var(--module-bg);
}
.__2B9Hs4eE.DMPU_uJo .wNK2gADi .VPxHjsFa {
  height: 58px;
  padding: 16px 32px;
  border-bottom: 1px solid var(--border-color);
  font-size: 24px;
  font-weight: 700;
}
.__2B9Hs4eE.DMPU_uJo .wNK2gADi .COQAK9FQ {
  display: flex;
  flex-wrap: wrap;
  min-height: 826px;
  max-height: 2000px;
  padding: 24px 21px 0;
  overflow-y: scroll;
  margin-bottom: -430px;
}
.__2B9Hs4eE .tPVL6Wnv {
  width: 276px;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 16px;
  top: calc(100% - var(--aside-height) - 24px);
  position: sticky;
}
.__2B9Hs4eE .freIvJzT {
  flex: 1;
  width: calc(100% - 276px - 200px - 32px);
  background: var(--module-bg);
}
.__2B9Hs4eE.HcVotRm_ .M_tC2yUh {
  width: 276px;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 16px;
  position: sticky;
  top: calc(100% - var(--aside-height) - 24px);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm {
  flex: 1;
  width: calc(100% - 276px - 200px - 32px);
  background: var(--module-bg);
  padding-top: 24px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby {
  width: 689px;
  padding: 0 25px;
  margin-bottom: 40px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .ant-carousel .slick-dots.slick-dots-bottom {
  bottom: 15px;
  margin-left: 8%;
  justify-content: flex-start;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .ant-carousel .slick-dots.slick-dots-bottom > li {
  width: 13px;
  height: 15px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .ant-carousel .slick-dots.slick-dots-bottom > li > button {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--btn-grey1-bg);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .ant-carousel .slick-dots.slick-dots-bottom li.slick-active {
  width: 13px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .ant-carousel .slick-dots.slick-dots-bottom li.slick-active > button {
  background: var(--main-color);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .o5zO3iXI {
  position: relative;
  cursor: pointer;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .o5zO3iXI .aXzDBPg3 {
  width: 405px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 55px;
  z-index: 99;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .o5zO3iXI .aXzDBPg3 .yTrfp0xQ {
  line-height: 30px;
  margin-top: 13px;
  font-size: 20px;
  font-weight: 700;
  color: var(--white);
  font-family: var(--CommonBold);
  -webkit-line-clamp: 2;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .o5zO3iXI .aXzDBPg3 .__8yPr2S11 {
  display: flex;
  align-items: center;
  margin-top: 16px;
  float: right;
  font-weight: 700;
  color: var(--white);
  font-family: var(--CommonBold);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .o5zO3iXI .aXzDBPg3 .__8yPr2S11 .zuWVB0HX {
  display: inline-block;
  width: 51px;
  margin-right: 10px;
  border-bottom: 1px solid var(--white);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev {
  margin-bottom: 32px;
  padding: 0 25px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-prev,
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-next,
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-prev:hover,
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-next:hover {
  width: 36px;
  height: 36px;
  font-size: inherit;
  color: currentColor;
  z-index: 99999;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-next {
  transform: rotate(180deg);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-prev {
  left: -13px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-next {
  right: -13px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-next::before,
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-prev::before {
  content: "";
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .I5gUbpxE {
  display: flex;
  justify-content: space-between;
  height: 56px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .I5gUbpxE .VSWs_IOz {
  font-size: 22px;
  font-weight: 700;
  font-family: var(--CommonBold);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl {
  width: 642px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI {
  width: 214px;
  height: 290px;
  padding-top: 23px;
  position: relative;
  cursor: pointer;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .PFLx9fzy {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 0;
  left: 88px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .PFLx9fzy .WBNZW7IH {
  border: 1px solid var(--avator-border) !important;
  border-radius: 50%;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW {
  height: 266px;
  margin: 0 8px;
  padding: 16px;
  padding-top: 40px;
  background: var(--main-bg-color);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW:hover {
  background: var(--table-hover-bg);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .__8yPr2S11 {
  color: var(--white);
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  font-family: var(--CommonMedium);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .MTL8_yJw {
  justify-content: center;
  margin-top: 8px;
  overflow: hidden;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .tc87JYPV {
  margin-top: 16px;
  font-size: 12px;
  color: var(--text-color);
  font-family: var(--CommonRegular);
  -webkit-line-clamp: 5;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .ant-btn {
  width: 100px;
  height: 24px;
  line-height: 1;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .lgIZlq2d {
  position: absolute;
  bottom: 16px;
  width: 171px;
  color: var(--main-color);
  border-color: var(--main-color);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .lgIZlq2d:hover {
  background: var(--main-color);
  color: var(--white);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .__9Zqo_E1M {
  position: absolute;
  bottom: 16px;
  width: 171px;
  background: var(--btn-disabled-bg);
  border-color: var(--btn-disabled-bg);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .ant-btn-primary:hover {
  background: var(--btn-disabled-bg) !important;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .NCsbjFDt {
  overflow: hidden;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .NCsbjFDt .VSWs_IOz {
  padding: 0 25px;
  height: 32px;
  font-size: 22px;
  font-weight: 700;
  font-family: var(--CommonBold);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .NCsbjFDt .__8L724Zv3 {
  padding: 24px 25px 0;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .NCsbjFDt .__8L724Zv3:hover {
  background: var(--table-hover-bg);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .NCsbjFDt .__8L724Zv3 .yTrfp0xQ:hover {
  text-decoration: underline;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .NCsbjFDt .__8L724Zv3 .__4M91D0Ph {
  margin-top: 24px;
  margin-bottom: 0;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW {
  width: calc(100% - 340px);
  background: var(--module-bg);
  overflow: hidden;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ {
  padding: 23px 24px 0;
  min-height: 826px;
  max-height: 2000px;
  overflow-y: scroll;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ ._gVlx_HK {
  padding: 24px 0;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ ._gVlx_HK .Xr6ISUGV {
  padding: 0 16px;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ ._gVlx_HK .tc87JYPV {
  padding: 0 16px;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ ._gVlx_HK .__8L724Zv3 {
  margin-bottom: 0;
  padding: 8px 16px;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ ._gVlx_HK .__8L724Zv3:hover {
  background: var(--table-hover-bg);
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ ._AO_7UOr {
  height: 220px;
  bottom: 100px;
  left: 20px;
  margin-left: -36px;
  margin-bottom: -100px;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ ._AO_7UOr > .CsPTRZa3 {
  margin-top: 130px;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ .__9AyLqG0O {
  margin-top: 0;
}
.__2B9Hs4eE.bwcwIG1J .UkhcEGjT {
  width: 340px;
  margin-left: 16px;
}
.__2B9Hs4eE.bwcwIG1J .UkhcEGjT .xTUDjPxT {
  padding-bottom: 0;
  min-height: 826px;
  max-height: 2000px;
  overflow-y: scroll;
}
.__2B9Hs4eE.bwcwIG1J .UkhcEGjT ._gVlx_HK {
  height: unset;
  background: transparent;
  padding: 0;
  margin: 0;
}
.__2B9Hs4eE.bwcwIG1J .UkhcEGjT ._gVlx_HK .Xr6ISUGV {
  margin-bottom: 24px;
}
.__2B9Hs4eE.bwcwIG1J .UkhcEGjT .CsPTRZa3 {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.__2B9Hs4eE.bwcwIG1J .UkhcEGjT .CsPTRZa3:hover {
  color: var(--main-text-hover-color);
}
.__2B9Hs4eE.bwcwIG1J .UkhcEGjT .__9AyLqG0O {
  margin-top: 0;
}
.__2B9Hs4eE .__6TaoxYWZ {
  background: var(--module-bg);
}
.__2B9Hs4eE .__6TaoxYWZ ._AO_7UOr {
  height: 230px;
  bottom: 230px;
}
.__2B9Hs4eE .__6TaoxYWZ ._AO_7UOr > .CsPTRZa3 {
  margin-top: 150px;
}
.__2B9Hs4eE .__1DkRjV_r {
  width: 340px;
  background: var(--module-bg);
  min-height: 826px;
}
.__2B9Hs4eE .__1DkRjV_r .X2kOKGcM {
  margin: 0 0 20px;
  padding: 0 24px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}
.__2B9Hs4eE .__1DkRjV_r .X2kOKGcM .VSWs_IOz {
  font-size: 20px;
  font-weight: 700;
}
.__2B9Hs4eE .__1DkRjV_r .X2kOKGcM .__2mu72Sbe {
  font-size: 20px;
  cursor: pointer;
}
.__2B9Hs4eE .__1DkRjV_r .X2kOKGcM .__2mu72Sbe :hover {
  color: var(--main-color);
}
.__2B9Hs4eE .__1DkRjV_r .mT0VVhYO {
  max-height: 750px;
  overflow: auto;
}
.__2B9Hs4eE .__1DkRjV_r .mT0VVhYO .XFlBSv_K {
  padding: 0 24px 16px;
}
.__2B9Hs4eE .__1DkRjV_r .mT0VVhYO .infinite-scroll-component {
  padding-top: 10px;
}
.__2B9Hs4eE .__1DkRjV_r .uaZlN7IZ {
  display: inline-block;
  padding: 1px 6px;
  margin-left: 0;
  background: var(--main-bg-color);
  border-radius: 2px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  margin-bottom: 13px;
}
.__2B9Hs4eE .__1DkRjV_r .RJizd0Uf {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
  margin-bottom: 6px;
  max-width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.__2B9Hs4eE .__1DkRjV_r .ZkK8Xkvh {
  font-size: 13px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  -webkit-line-clamp: 2;
}
.__2B9Hs4eE .__1DkRjV_r .s0rJbJxY {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
}
.__2B9Hs4eE .__1DkRjV_r .shgGV08Q .ant-timeline-item-head {
  background-color: var(--btn-red-bg);
}
.__2B9Hs4eE .__1DkRjV_r .ant-timeline-item {
  cursor: pointer;
}
.__2B9Hs4eE .__1DkRjV_r .ant-timeline-item-head {
  width: 7px;
  height: 7px;
  border: none;
  background-color: var(--btn-grey1-bg);
}
.__2B9Hs4eE .__1DkRjV_r .ant-timeline-item-tail {
  border: 1px dashed var(--border-color);
  background: transparent;
  left: 3px;
}
.__2B9Hs4eE .__1DkRjV_r .ant-timeline-item-last > .ant-timeline-item-tail {
  display: block;
}
.__2B9Hs4eE .__1DkRjV_r .ant-timeline-item-last .ant-timeline-item-tail {
  height: 90px;
}
.__2B9Hs4eE .__1DkRjV_r .ant-skeleton .ant-skeleton-header {
  padding-left: 24px;
}
.__2B9Hs4eE .__1DkRjV_r .ant-skeleton .ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.__2B9Hs4eE .__1DkRjV_r .ant-skeleton .ant-skeleton-content {
  padding-right: 24px;
}
.__2B9Hs4eE .__1DkRjV_r .ant-skeleton .ant-skeleton-content .ant-skeleton-title {
  margin: -2px;
}
.__2B9Hs4eE .__1DkRjV_r .ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph {
  margin-top: 20px;
  margin-bottom: 10px;
}
.yTrfp0xQ,
.wkoEmufU,
.ZkK8Xkvh,
.FB19Yg9N,
.tc87JYPV {
  /* Compose 组合样式 */
}
._FtTUDm6 {
  flex-flow: row nowrap !important;
}
.__7mnpHnJj {
  padding: 24px 32px 0;
  cursor: pointer;
}
.__7mnpHnJj:hover {
  background: var(--table-hover-bg);
}
.__7mnpHnJj .L8UALdWL {
  margin-left: 24px;
}
.__7mnpHnJj .L8UALdWL span {
  width: 152px !important;
  height: 114px !important;
  margin: auto !important;
}
.__7mnpHnJj .L8UALdWL .WBNZW7IH {
  border-radius: 2px;
}
.__7mnpHnJj .pf8_Deh9 {
  flex: 1;
  overflow: hidden;
  max-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.__7mnpHnJj .pf8_Deh9 .yTrfp0xQ {
  font-size: 16px;
  font-weight: 700;
  color: var(--white);
  font-family: var(--CommonRegular);
  -webkit-line-clamp: 1;
}
.__7mnpHnJj .pf8_Deh9 .wkoEmufU {
  font-family: var(--CommonMedium);
  font-weight: 400;
  color: var(--text-color) !important;
  -webkit-line-clamp: 2;
}
.__7mnpHnJj .pf8_Deh9 .wkoEmufU span {
  color: var(--text-color) !important;
  background-color: var(--module-bg) !important;
  font-size: 14px !important;
}
.__7mnpHnJj .pf8_Deh9 .wkoEmufU img {
  display: none !important;
}
.__7mnpHnJj .pf8_Deh9 .gOfyAIag {
  line-height: 24px;
  display: flex;
  align-items: center;
}
.__7mnpHnJj .pf8_Deh9 .gOfyAIag .__3wR0j2fU {
  max-width: 110px;
  height: 18px;
  background: var(--btn-type-bg);
  color: var(--main-color);
  border-radius: 2px;
  padding: 1px 8px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  line-height: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.__7mnpHnJj .pf8_Deh9 .gOfyAIag .__4M91D0Ph {
  height: 12px;
  border-left: 1px solid var(--border-color2);
  margin: 0 16px;
}
.__7mnpHnJj .pf8_Deh9 .gOfyAIag .bHfUg5sl {
  color: var(--text-color);
  margin-right: 6px;
}
.__7mnpHnJj .pf8_Deh9 .gOfyAIag .__7noh7e74 {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.__7mnpHnJj .pf8_Deh9 .gOfyAIag .C7mLmc4o {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
.__7mnpHnJj .JwBZPoEB {
  width: 100%;
  margin-top: 24px;
  border-bottom: 1px dashed var(--border-color);
}
.__7EmYL6MW .gOfyAIag {
  margin-top: 16px;
}
.__7EmYL6MW .gOfyAIag .__3wR0j2fU {
  margin-right: 10px;
}
.__7EmYL6MW .gOfyAIag .__3wR0j2fU:last-of-type {
  margin-right: 0;
}
.__5wFMP9Ln {
  width: 100%;
  height: 380px;
  background: var(--main-bg-color);
  margin-bottom: 25px;
  position: relative;
  cursor: pointer;
  border-radius: 2px;
}
.__5wFMP9Ln:hover {
  background: var(--table-hover-bg);
}
.__5wFMP9Ln .WBNZW7IH {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.__5wFMP9Ln:nth-child(3n) {
  margin: 0;
}
.__5wFMP9Ln .pf8_Deh9 {
  height: 210px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.__5wFMP9Ln .pf8_Deh9 .yTrfp0xQ {
  height: 66px;
  vertical-align: middle;
  font-size: 18px;
  font-weight: 700;
  color: var(--white);
  font-family: var(--CommonRegular);
  -webkit-line-clamp: 3 !important;
}
.__5wFMP9Ln .pf8_Deh9 .FB19Yg9N {
  height: 80px;
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
  font-family: var(--CommonMedium);
  color: var(--text-color) !important;
  -webkit-line-clamp: 4 !important;
}
.__5wFMP9Ln .pf8_Deh9 .t5hGvpRZ {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  margin-top: 13px;
}
.__5wFMP9Ln .U8fb8jza {
  position: absolute;
  top: 8px;
  left: 11px;
  background: var(--main-color);
  border: none;
  color: var(--white);
  padding: 4px 9px;
  height: 22px;
  font-size: 12px;
  line-height: 15px;
}
._gVlx_HK {
  position: relative;
  height: 540px;
  background: var(--main-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 16px;
  margin-bottom: 16px;
}
._gVlx_HK .Xr6ISUGV {
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}
._gVlx_HK .Xr6ISUGV .WBNZW7IH {
  border: 1px solid var(--avator-border) !important;
  border-radius: 50%;
}
._gVlx_HK .Xr6ISUGV .__8oY6qpnJ {
  width: calc(100% - 48px - 16px);
  margin-left: 16px;
  overflow: hidden;
}
._gVlx_HK .Xr6ISUGV .__8oY6qpnJ .__8yPr2S11 {
  font-size: 14px;
  font-weight: 700;
  font-family: var(--CommonMedium);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._gVlx_HK .Xr6ISUGV .__8oY6qpnJ .MTL8_yJw {
  margin-top: 10px;
}
._gVlx_HK .tc87JYPV {
  height: 88px;
  margin-top: 16px;
  font-size: 12px;
  color: var(--text-color);
  -webkit-line-clamp: 4 !important;
}
._gVlx_HK .__4M91D0Ph {
  margin: 16px 0;
  border-bottom: 1px solid var(--border-color1);
}
._gVlx_HK .__8L724Zv3 {
  margin-bottom: 16px;
  cursor: pointer;
}
._gVlx_HK .__8L724Zv3 .C7mLmc4o {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
._gVlx_HK .__8L724Zv3 .yTrfp0xQ {
  margin-top: 8px;
  font-size: 13px;
  font-weight: 500;
  color: var(--white);
  font-family: var(--CommonMedium);
  -webkit-line-clamp: 2;
}
._gVlx_HK .__8L724Zv3 .yTrfp0xQ:hover {
  text-decoration: underline;
}
._gVlx_HK .UEtYhhIU {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  padding: 0 16px;
}
._gVlx_HK .UEtYhhIU .ant-btn-primary {
  width: 100px;
  height: 24px;
  line-height: 1;
}
._gVlx_HK .UEtYhhIU .__9Zqo_E1M {
  background: var(--btn-grey-bg);
  border-color: var(--btn-grey-bg);
}
._gVlx_HK .UEtYhhIU .__9Zqo_E1M:hover {
  background: var(--btn-disabled-bg) !important;
}
._gVlx_HK .UEtYhhIU .yKYyyep2 {
  background: var(--expert-btn-view-bg);
  border-color: var(--expert-btn-view-bg);
}
._gVlx_HK .UEtYhhIU .yKYyyep2:hover {
  background: var(--expert-btn-view-bg-hover) !important;
}
.__6yhojelr {
  min-height: 826px;
  max-height: 1500px;
  overflow-y: scroll;
  margin-bottom: -200px;
}
.__6yhojelr .__8L724Zv3 .Obe0pLXe {
  display: flex;
  cursor: pointer;
}
.__6yhojelr .__8L724Zv3 .Obe0pLXe .WBNZW7IH {
  border: 1px solid var(--avator-border) !important;
  border-radius: 50%;
}
.__6yhojelr .__8L724Zv3 .Obe0pLXe .__8oY6qpnJ {
  width: calc(100% - 48px - 16px);
  margin-left: 16px;
  overflow: hidden;
}
.__6yhojelr .__8L724Zv3 .Obe0pLXe .__8oY6qpnJ .__8yPr2S11 {
  font-weight: 700;
  font-family: var(--CommonBold);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.__6yhojelr .__8L724Zv3 .Obe0pLXe .__8oY6qpnJ .C7mLmc4o {
  margin-top: 8px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
.__6yhojelr .__8L724Zv3 .U8fb8jza {
  margin-top: 16px;
}
.__6yhojelr .__8L724Zv3 .yTrfp0xQ {
  line-height: 26px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  font-family: var(--CommonMedium);
  -webkit-line-clamp: 1 !important;
}
.__6yhojelr .__8L724Zv3 .FB19Yg9N {
  margin-top: 16px;
  font-size: 13px;
  color: var(--text-color);
  -webkit-line-clamp: 2 !important;
}
.__6yhojelr ._AO_7UOr {
  height: 230px;
  bottom: 230px;
}
.__6yhojelr ._AO_7UOr > .CsPTRZa3 {
  margin-top: 180px;
}
.LgWdJOMO .wL1ZLoP1 {
  margin-top: 16px;
}
.LgWdJOMO .wL1ZLoP1 .xTUDjPxT {
  padding: 0;
}
.LgWdJOMO .__7mnpHnJj .pf8_Deh9 {
  min-height: 150px;
}
._mSWeuNJ .wL1ZLoP1,
.yo3iS3fS .wL1ZLoP1 {
  margin-top: 16px;
}
._mSWeuNJ .wL1ZLoP1 .I5gUbpxE,
.yo3iS3fS .wL1ZLoP1 .I5gUbpxE {
  padding: 16px 40px;
}
._mSWeuNJ .wL1ZLoP1 .xTUDjPxT,
.yo3iS3fS .wL1ZLoP1 .xTUDjPxT {
  padding: 0;
}
._mSWeuNJ .wL1ZLoP1 .xTUDjPxT .GGmNZ23a,
.yo3iS3fS .wL1ZLoP1 .xTUDjPxT .GGmNZ23a {
  display: flex;
  flex-wrap: wrap;
  min-height: 558px;
  padding: 18px 40px 0;
}
.idsHz4Kn {
  width: 136px;
  height: 200px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.idsHz4Kn .gs8DD1s5 {
  font-size: 14px;
  margin-top: 16px;
  color: var(--text-color);
}
.__1bHu_Eka {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  color: var(--remark-text-color);
}
.OCbS3MgV {
  background: var(--module-bg);
  padding: 16px 32px 40px;
}
.OCbS3MgV h1,
.OCbS3MgV h2,
.OCbS3MgV h3,
.OCbS3MgV h4 {
  line-height: 40px !important;
}
.OCbS3MgV ul,
.OCbS3MgV ol {
  list-style: disc inside;
  text-indent: 2em;
}
.OCbS3MgV .ZEhNDfpR {
  margin-bottom: 30px;
}
.OCbS3MgV img {
  height: unset !important;
  border-radius: 2px;
}
.OCbS3MgV .VSWs_IOz {
  font-size: 36px;
  font-weight: 700;
  font-family: var(--CommonMedium);
  margin-bottom: 20px;
  line-height: normal;
}
.OCbS3MgV .faYarH0C {
  background: var(--main-bg-color);
  padding: 16px 24px;
  margin: 30px 0;
}
.OCbS3MgV .faYarH0C .__5HB3qqQT {
  margin-bottom: 8px;
  font-size: 16px;
  color: var(--main-color);
  font-family: var(--CommonBold);
}
.OCbS3MgV .faYarH0C ._k_CvG34 {
  font-size: 16px;
  font-family: var(--CommonRegular);
  line-height: normal;
  color: var(--text-color);
}
.OCbS3MgV p,
.OCbS3MgV div {
  word-wrap: break-word;
}
.OCbS3MgV .gOfyAIag {
  margin-bottom: 30px;
  line-height: 24px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.OCbS3MgV .gOfyAIag .ontNQkzj {
  display: flex;
  align-items: center;
}
.OCbS3MgV .gOfyAIag .ontNQkzj .__3wR0j2fU:last-of-type {
  margin-right: 0;
}
.OCbS3MgV .gOfyAIag .ontNQkzj .__4M91D0Ph {
  height: 12px;
  border-left: 1px solid var(--border-color2);
  margin: 0 16px;
}
.OCbS3MgV .gOfyAIag .ontNQkzj .bHfUg5sl {
  color: var(--text-color);
}
.OCbS3MgV .gOfyAIag .ontNQkzj .__7noh7e74 {
  margin-left: 6px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.OCbS3MgV .gOfyAIag .ontNQkzj .C7mLmc4o {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
.OCbS3MgV .gOfyAIag .dpNMXs5V {
  display: flex;
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .bJ9lB1NF {
  color: var(--text-color);
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .OZbiuCZ6 {
  max-width: 300px;
  padding: 3px 12px;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .OZbiuCZ6:hover {
  background: var(--main-color);
  color: var(--white);
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .sEXxpj__ {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 16px;
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .sEXxpj__ .WBNZW7IH {
  border: 1px solid var(--avator-border) !important;
  border-radius: 50%;
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .sEXxpj__ .p2XjUDjG {
  margin-left: 9px;
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .UEtYhhIU .ant-btn-primary {
  padding: 4px 27px;
  height: 24px;
  line-height: 1;
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .UEtYhhIU .__9Zqo_E1M {
  background: var(--btn-grey-bg);
  border-color: var(--btn-grey-bg);
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .UEtYhhIU .__9Zqo_E1M:hover {
  background: var(--btn-disabled-bg) !important;
}
.OCbS3MgV .JwBZPoEB {
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid var(--border-color);
}
.OCbS3MgV .__7soLLh6M {
  color: var(--text-color-third);
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 30px;
}
.OCbS3MgV .__7soLLh6M p {
  text-indent: 0;
}
.OCbS3MgV .rDiWXRQg {
  display: flex;
  align-items: center;
  color: var(--white);
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.OCbS3MgV .rDiWXRQg:hover {
  color: var(--main-color);
}
.OCbS3MgV .rDiWXRQg .__0izole0m {
  width: 100%;
  margin-left: 9px;
  white-space: nowrap;
  overflow: hidden;
}
.OCbS3MgV .Nqsk4DeY {
  font-size: 12px;
  color: var(--remark-text-color);
}
.OCbS3MgV .EAwpUvjr {
  width: 100%;
  text-align: center;
}
.OCbS3MgV .EAwpUvjr .BnUFfyTh {
  font-size: 18px;
  color: var(--main-color);
}
.OCbS3MgV .EAwpUvjr .BnUFfyTh .QoZZs6sv {
  text-decoration: underline;
}
.pweRQnUS {
  margin-bottom: 24px;
  border-bottom: 1px dashed var(--border-color);
}
.pweRQnUS:last-of-type {
  border: none;
  margin-bottom: 0;
}
.pweRQnUS .Xr6ISUGV {
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}
.pweRQnUS .Xr6ISUGV .WBNZW7IH {
  border: 1px solid var(--avator-border) !important;
  border-radius: 50%;
}
.pweRQnUS .Xr6ISUGV .__8oY6qpnJ {
  width: calc(100% - 48px - 21px);
  margin-left: 21px;
  overflow: hidden;
}
.pweRQnUS .Xr6ISUGV .__8oY6qpnJ .__8yPr2S11 {
  font-size: 14px;
  font-weight: 700;
  font-family: var(--CommonMedium);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pweRQnUS .Xr6ISUGV .__8oY6qpnJ .MTL8_yJw {
  margin-top: 12px;
}
.pweRQnUS .yTrfp0xQ {
  cursor: pointer;
  margin: 16px 0;
  color: var(--white);
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 500;
  -webkit-line-clamp: 2;
}
.pweRQnUS .yTrfp0xQ:hover {
  text-decoration: underline;
}
.pweRQnUS .C7mLmc4o {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  margin-bottom: 24px;
}
.Y1tIxDgV .__8yPr2S11 {
  margin-left: 21px;
  font-size: 16px;
  font-weight: 700;
  width: calc(100% - 48px - 21px);
  font-family: var(--CommonMedium);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Y1tIxDgV .yTrfp0xQ:hover {
  text-decoration: underline;
}
.HxN_0FRR {
  display: flex;
  margin-bottom: 32px;
  cursor: pointer;
}
.HxN_0FRR:last-of-type {
  margin-bottom: 0;
}
.HxN_0FRR .tHthWjSi {
  color: var(--main-color);
  font-size: 20px;
  font-weight: 700;
  font-family: var(--CommonBold);
  font-style: italic;
}
.HxN_0FRR .yTrfp0xQ {
  font-weight: 500;
  font-size: 14px;
  font-family: var(--CommonRegular);
  color: var(--white);
  margin-left: 17px;
  -webkit-line-clamp: 2;
}
.HxN_0FRR .yTrfp0xQ:hover {
  text-decoration: underline;
}
.RIRsN8nI {
  cursor: pointer;
}
.RIRsN8nI .rmpHvrlW {
  display: flex;
  flex-shrink: 0;
}
.RIRsN8nI .rmpHvrlW .wJyQONGn {
  flex: 1;
}
.RIRsN8nI .rmpHvrlW .wJyQONGn .yTrfp0xQ {
  font-size: 14px;
  font-weight: 700;
  color: var(--white);
  font-family: var(--CommonRegular);
  -webkit-line-clamp: 2;
}
.RIRsN8nI .rmpHvrlW .wJyQONGn .yTrfp0xQ:hover {
  text-decoration: underline;
}
.RIRsN8nI .rmpHvrlW .wJyQONGn .t5hGvpRZ {
  margin-top: 8px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
.RIRsN8nI .rmpHvrlW .WBNZW7IH {
  border-radius: 4px;
}
.RIRsN8nI .__4M91D0Ph {
  margin: 16px 0;
  border-bottom: 1px dashed var(--border-color);
}
.EkymRSSd {
  padding: 24px 20px 27px;
  width: 340px;
  height: 210px;
  background: var(--module-bg);
  color: var(--white);
}
.EkymRSSd .z_eFSJSa {
  font-size: 22px;
  font-weight: 700;
  font-family: var(--CommonMedium);
}
.EkymRSSd .__1FSx5zqP {
  display: flex;
  margin-top: 31px;
}
.EkymRSSd .__1FSx5zqP .FfaRKtJi {
  font-size: 12px;
}
.EkymRSSd .__1FSx5zqP .__9PunJNYl {
  position: relative;
  margin-left: 24px;
}
.EkymRSSd .__1FSx5zqP .__9PunJNYl .zffvMtwI {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 40px;
  left: 40px;
}
.ojZoDH_Z {
  width: 340px;
  height: -moz-fit-content;
  height: fit-content;
  position: sticky;
  top: calc(100% - var(--aside-height) - 24px);
}
.ojZoDH_Z ._G4hqGAG ._gVlx_HK {
  padding: 24px 24px 10px;
}
.ojZoDH_Z ._gVlx_HK {
  height: auto;
  background: var(--module-bg);
  padding: 0;
  margin: 0;
}
.ojZoDH_Z ._gVlx_HK .Xr6ISUGV {
  margin-bottom: 24px;
}
.ojZoDH_Z ._gVlx_HK .tc87JYPV {
  height: auto;
}
.ojZoDH_Z ._gVlx_HK .__4M91D0Ph {
  border-bottom: 1px dashed var(--border-color);
}
.pK14a3b8 {
  max-width: 1200px;
  margin: auto;
}
.pK14a3b8 .ADoVvyle {
  width: 100%;
  height: 515px;
  padding-top: 55px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.pK14a3b8 .__2_3L_cTo {
  margin-top: 55px;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 {
  text-align: center;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .PFLx9fzy {
  border-radius: 50%;
  border: 3px solid var(--white) !important;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .__8yPr2S11 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  font-family: var(--CommonBold);
  margin: 17px 0;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .MTL8_yJw {
  justify-content: center;
  margin-bottom: 26px;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .MTL8_yJw .__3wR0j2fU {
  height: 28px;
  padding: 5px 12px;
  line-height: normal;
  background: var(--btn-type1-bg) !important;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .ant-btn-primary {
  height: 45px;
  padding: 9px 40px;
  font-size: 24px;
  font-family: var(--CommonMedium);
  line-height: 20px;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .__9Zqo_E1M {
  background: var(--btn-grey-bg);
  border-color: var(--btn-grey-bg);
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .__9Zqo_E1M:hover {
  background: var(--btn-disabled-bg) !important;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .yKYyyep2 {
  background: var(--expert-btn-view-bg);
  border-color: var(--expert-btn-view-bg);
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .yKYyyep2:hover {
  background: var(--expert-btn-view-bg-hover) !important;
}
.pK14a3b8 .aa1p9ryV {
  height: 100%;
  display: flex;
  margin-top: 40px;
  position: relative;
}
.pK14a3b8 .aa1p9ryV .h_KjxH1z {
  margin-bottom: 16px;
  width: 100%;
  padding: 40px;
  background: var(--module-bg) !important;
  z-index: 99999;
}
.pK14a3b8 .aa1p9ryV .h_KjxH1z .VSWs_IOz {
  font-size: 22px;
  font-weight: 700;
  font-family: var(--CommonBold);
}
.pK14a3b8 .aa1p9ryV .h_KjxH1z .IQYhi_Qi {
  display: flex;
}
.pK14a3b8 .aa1p9ryV .h_KjxH1z .IQYhi_Qi .tc87JYPV {
  padding-top: 28px;
  font-size: 14px;
  color: var(--text-color);
  text-align: justify;
  font-family: var(--CommonRegular);
  -webkit-line-clamp: 10;
  position: relative;
}
.pK14a3b8 .aa1p9ryV .h_KjxH1z .IQYhi_Qi .tc87JYPV.xxSo45dU {
  -webkit-line-clamp: 9999;
  max-height: none;
}
.pK14a3b8 .aa1p9ryV .h_KjxH1z .IQYhi_Qi .tc87JYPV::before {
  content: "";
  height: calc(100% - 22px);
  float: right;
}
.pK14a3b8 .aa1p9ryV .h_KjxH1z .IQYhi_Qi .tc87JYPV .nx6NM8aT {
  float: right;
  clear: both;
}
.pK14a3b8 .RnoGpdBL {
  min-height: var(--author-detail-aside-height);
}
.pK14a3b8 .RnoGpdBL .I5gUbpxE {
  padding-left: 40px;
}
.pK14a3b8 .RnoGpdBL .xTUDjPxT {
  padding: 24px 0;
  overflow: hidden;
}
.pK14a3b8 .RnoGpdBL .xTUDjPxT .__6yhojelr {
  padding: 0 24px 0 40px;
}
.pK14a3b8 .RnoGpdBL .xTUDjPxT .__6yhojelr .__8L724Zv3 .C41daqCC {
  display: flex;
  align-items: center;
}
.pK14a3b8 .RnoGpdBL .xTUDjPxT .__6yhojelr .__8L724Zv3 .C41daqCC .U8fb8jza {
  margin-top: 0;
}
.pK14a3b8 .RnoGpdBL .xTUDjPxT .__6yhojelr .__8L724Zv3 .C41daqCC .C7mLmc4o {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
.pK14a3b8 .b_e1QHNi {
  width: 340px;
  background: var(--module-bg);
  min-height: 826px;
  max-height: 1500px;
  margin-left: 16px;
}
.pK14a3b8 .b_e1QHNi .ThthsEmT ._G4hqGAG ._gVlx_HK {
  padding: 24px 24px 10px;
}
.pK14a3b8 .b_e1QHNi .ThthsEmT ._gVlx_HK {
  height: auto;
  background: var(--module-bg);
  padding: 0;
  margin: 0;
}
.pK14a3b8 .b_e1QHNi .ThthsEmT ._gVlx_HK .Xr6ISUGV {
  margin-bottom: 24px;
}
.pK14a3b8 .b_e1QHNi .ThthsEmT ._gVlx_HK .tc87JYPV {
  height: auto;
  -webkit-line-clamp: 6 !important;
}
.pK14a3b8 .b_e1QHNi .ThthsEmT ._gVlx_HK .__4M91D0Ph {
  border-bottom: 1px dashed var(--border-color);
}
._AO_7UOr {
  margin-left: -32px;
  width: calc(100% + 64px);
  height: 430px;
  text-align: center;
  position: relative;
  bottom: 430px;
  left: 0;
  overflow-y: scroll;
  background: -webkit-linear-gradient(var(--loadMore-bg1-color) 0%, var(--loadMore-bg2-color) 32.29%, var(--module-bg) 70%, var(--module-bg));
}
._AO_7UOr .CsPTRZa3 {
  margin-top: 330px;
  color: var(--main-color);
  border-color: var(--main-color);
}
._AO_7UOr .CsPTRZa3:hover {
  background: var(--main-color);
  color: var(--white);
}
.__9AyLqG0O {
  width: 100%;
  margin: 10px auto;
  font-size: 12px;
  text-align: center;
  color: var(--remark-text-color);
}
.__0cN_W_Lv {
  height: 251px;
}
.__0cN_W_Lv .Md0KFpmG {
  width: 100%;
  height: 251px;
}
.__0cN_W_Lv .Md0KFpmG .cBNS3YGv {
  height: 84px;
  bottom: 0;
  background: linear-gradient(270deg, rgba(31, 33, 47, 0.9) 46.94%, rgba(31, 33, 47, 0.3) 100%);
}
@media only screen and (max-width: 600px) {
  .LgWdJOMO.__6bOtwHIt,
  .yo3iS3fS.__6bOtwHIt,
  ._mSWeuNJ.__6bOtwHIt,
  .I9DePsyy.__6bOtwHIt {
    width: 100% !important;
    min-height: 100vh !important;
    background: var(--black);
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV {
    background: var(--black);
    width: 100% !important;
    margin: 0 !important;
    padding: 32px 24px 60px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .VSWs_IOz,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .VSWs_IOz,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .VSWs_IOz,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .VSWs_IOz {
    font-size: 18px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .C41daqCC,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .C41daqCC,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .C41daqCC,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .C41daqCC {
    display: flex;
    flex: 1;
    justify-content: space-between;
    font-size: 10px;
    color: var(--text-color);
    margin-bottom: 16px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza {
    display: flex;
    flex-wrap: wrap;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza .__3wR0j2fU,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza .__3wR0j2fU,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza .__3wR0j2fU,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza .__3wR0j2fU {
    max-width: 80px;
    background: var(--btn-type-bg);
    padding: 1px 4px;
    color: var(--main-color);
    border-radius: 2px;
    margin-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 3px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .C41daqCC .__4M91D0Ph,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .C41daqCC .__4M91D0Ph,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .C41daqCC .__4M91D0Ph,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .C41daqCC .__4M91D0Ph {
    margin: 0 5px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .KFULlSq7,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .KFULlSq7,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .KFULlSq7,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .KFULlSq7 {
    min-width: 110px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .__7soLLh6M p,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .__7soLLh6M p,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .__7soLLh6M p,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .__7soLLh6M p {
    font-size: 14px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .Xr6ISUGV,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .Xr6ISUGV,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .Xr6ISUGV,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .Xr6ISUGV {
    width: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 30px 0;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .WBNZW7IH,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .WBNZW7IH,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .WBNZW7IH,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .WBNZW7IH {
    border: 1px solid var(--avator-border) !important;
    border-radius: 50%;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ {
    width: calc(100% - 48px - 12px);
    margin-left: 12px;
    overflow: hidden;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .__8yPr2S11,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .__8yPr2S11,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .__8yPr2S11,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .__8yPr2S11 {
    font-size: 16px;
    font-weight: 700;
    font-family: var(--CommonMedium);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .MTL8_yJw,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .MTL8_yJw,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .MTL8_yJw,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .MTL8_yJw {
    margin-top: 10px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .faYarH0C,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .faYarH0C,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .faYarH0C,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .faYarH0C {
    background: var(--grey-level3);
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .faYarH0C .__5HB3qqQT,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .faYarH0C .__5HB3qqQT,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .faYarH0C .__5HB3qqQT,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .faYarH0C .__5HB3qqQT {
    font-size: 14px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .faYarH0C ._k_CvG34,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .faYarH0C ._k_CvG34,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .faYarH0C ._k_CvG34,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .faYarH0C ._k_CvG34 {
    font-size: 14px;
  }
}
.iYPybYUK {
  width: 100%;
  height: 100px;
  cursor: pointer;
}
.iYPybYUK .Tu6D2kfM {
  position: fixed;
  bottom: 28px;
  left: 24px;
  width: calc(100% - 58px);
  height: 85px;
  background: var(--grey-level3);
  border-radius: 6px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  font-size: 12px;
  color: var(--white);
}
.iYPybYUK .Tu6D2kfM .gs8DD1s5 {
  width: 170px;
  margin-left: 17px;
  margin-right: 25px;
}
.iYPybYUK .Tu6D2kfM .L0js0ZDm {
  width: 56px;
  height: 26px;
  background: var(--main-color);
  text-align: center;
  line-height: 24px;
  border-radius: 43px;
}
.iYPybYUK .Tu6D2kfM .L0js0ZDm > a {
  color: var(--white);
}
/*竖屏 css*/
@media screen and (orientation: portrait) {
  .__2B9Hs4eE.HcVotRm_ .M_tC2yUh {
    position: static;
  }
  .tPVL6Wnv {
    position: static !important;
  }
  .ojZoDH_Z {
    position: static;
  }
}

